<template>
    <section>
        <SearchBar :placeholder="'Search course'" :value.sync="search" @search="page = 1, getCourseEnrollment()"/>
        <!-- <v-text-field
			solo
			dense
			flat
			class="col-xl-3 col-lg-4 custom-border mb-5 general-custom-field f14 poppins fw500"
			hide-details
			prepend-inner-icon="mdi-magnify"
			placeholder="Search"
			v-model="search"
		/> -->
        
        <section v-if="totalCount !== 0" class="mb-3 text-right">
            <FormLabel :label="`${totalCount} result/s`"/>
        </section>
        <v-data-table
            :loading="loading"
            :headers="courseEnrollment"
            :items="enrollees"
            hide-default-footer
            :items-per-page.sync="paginate"
            class="text--center custom-border poppins f14">
            <template v-slot:header.pending_invoices="{ header }" >
                <span dense class="secondary-2--text px-2 border" style="background-color: #BDBDBD33">{{ header.text  }}</span>
            </template>
            <template v-slot:header.success_invoices="{ header }" >
                <span dense class="success--text px-2 border" style="background-color: #7BC14533">{{ header.text  }}</span>
            </template>
            <template v-slot:header.fail_invoices="{ header }" >
                <span dense class="danger--text px-2 border" style="background-color: #F8835E33">{{ header.text  }}</span>
            </template>
            <template v-slot:item.instructor="{ item }" >
                {{ item.uploader.first_name && item.uploader.first_name  }} {{ item.uploader.last_name && item.uploader.last_name }}
            </template>
            <template v-slot:item.action="{ item }" >
                <v-btn dense icon color="primary" @click="$router.push({ name: 'Admin User Enrollment', params: { course_uuid: item.uuid }})">
                    <v-icon small>
                        mdi-eye-outline
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <FormPagination 
            :pageCount="pageCount" 
            :page="page"
            :paginate="paginate"
            @page="(e) => page = e" 
            @paginate="(e) => paginate = e"/>
    </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { courseEnrollment } from '../../../constants/tblheaders/enrollment';
export default {
    data: () => ({
        search: '',
        loading: false,
        courseEnrollment,
        totalCount: 0,
        pageCount: 1,
        paginate: '10',
        page: 1,
        search: '',
    }),
    methods: {
        ...mapActions('admin', ['getEnrolleesAction']),
        ...mapMutations(['alertMutation']),

        getCourseEnrollment(){
            this.loading = true

            this.getEnrolleesAction({ paginate: Number(this.paginate), page: this.page, search: this.search }).then(res => {
                this.loading = false
                this.totalCount = res.total
                this.pageCount = res.last_page
                this.page = res.current_page
            }).catch(e => {
                this.loading = false
                this.alertMutation({
                    show: true,
                    text: 'Something went wrong.',
                    type: "error"
                })
            })
        }
    },
    computed: {
        ...mapState('admin', {
            enrollees: (state) => state.enrollees
        })
    },
    mounted() {
        this.getCourseEnrollment()
    },
    watch: {
        page(){
            this.getCourseEnrollment()
        },
        paginate(){
            this.page = 1
            this.getCourseEnrollment()
        },
    }
}
</script>
